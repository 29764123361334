<script lang="ts">
	import { getTranslate } from "../utils/getTranslate";
	import type { NewsForCard } from "./NewsCardProps";
	import { nbspify } from "../../../core/utils/nbspify";
	import { formatDateTime } from "../../../core/utils/formatDateTime";
	import translations from "./ArticleCard.translations.json";
	import logo from "../../../core/assets/logo-vertiflex-black.svg?raw";
	import arrowRightLong from "../../../core/assets/icons/arrow-right-long.svg?raw";
	import DynamicPicture from "./Picture/DynamicPicture.svelte";
	import Icon from "./Icon.svelte";
	import Link from "./Link.svelte";
	import { getBlogDetailPath } from "../../../core/schema/paths/getBlogDetailPath.js";

	export let article: NewsForCard;
	export let colStart: number | undefined = undefined;

	const translate = getTranslate(translations);
</script>

<div
	class="border-border group flex h-full w-full flex-col justify-between overflow-hidden rounded-lg border-2 bg-gray-300 p-2 duration-300 ease-in-out lg:min-h-[37.5rem] lg:p-4 xl:max-w-[21.875rem]"
	style:grid-column-start={colStart}
	class:row-start-1={colStart}
	class:row-end-1={colStart}
>
	<div class="flex flex-col gap-5">
		<a href="/{getBlogDetailPath(article)}">
			{#if article.primaryImage}
				<DynamicPicture
					class="h-[18rem] w-full overflow-hidden object-cover object-center"
					imgClass="w-full h-full object-cover object-center rounded-lg md:rounded-none"
					alt={article.title}
					image={article.primaryImage}
					width={0}
					height={400}
					bucket="storage"
					sourcesets={{
						"<md": [550, 0],
						">md": [684, 0],
						">lg": [440, 0],
						">xl": [306, 288],
					}}
				/>
			{:else}
				<div class="flex h-[18rem] w-full items-center justify-center bg-white">
					<Icon class="m-auto h-[5rem] w-[5rem]" icon={logo} />
				</div>
			{/if}
		</a>
		<article class="relative z-[1] flex flex-col gap-4 bg-gray-300">
			{#if article.publishedAt}
				<time class="text-4xs font-medium">{formatDateTime(article.publishedAt, article.locale)}</time>
			{/if}
			<p
				class="text-secondary font-spaceGrotesk group-hover:text-primary text-2md row-span-2 m-0 line-clamp-2 p-0 font-bold leading-[1.27]"
			>
				<a href="/{getBlogDetailPath(article)}">{nbspify(article.title)}</a>
			</p>
			<div class="text-2xs lg:text-2xs row-span-6 line-clamp-4 tracking-wide lg:line-clamp-3 lg:leading-[1.6]">
				{nbspify(article.perex)}
			</div>
		</article>
	</div>
	<div class="flex flex-wrap items-end justify-start">
		<Link
			aria-label={translate("readMoreAria", {}, true)}
			asButton
			class="mt-6 font-bold"
			variant="secondary"
			icon={arrowRightLong}
			href="/{getBlogDetailPath(article)}">{translate("readMore")}</Link
		>
	</div>
</div>
