import { Locale } from "../schema/Locale.js";

const dateMonthYearFormat: Record<Locale, Intl.DateTimeFormat> = {
	[Locale.cs]: new Intl.DateTimeFormat("cs-CZ", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
		timeZone: "Europe/Prague",
	}),
	[Locale.en]: new Intl.DateTimeFormat("en-GB", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
		timeZone: "Europe/Prague",
	}),
};

export function formatDateTime(date: number | Date | string, locale: Locale): string {
	return dateMonthYearFormat[locale].format(typeof date === "string" ? new Date(date) : date);
}
